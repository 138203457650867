


































































import { Component, Prop, Vue } from "vue-property-decorator";
import AlertModule, { AlertType } from "@/store/modules/alert";
import axios, { APIResponse } from "@/plugins/axios";
import { UserAddress, City, Country } from "@/types/user/address";
import { DataTableHeader } from "vuetify/types/";
import AdminBaseItemTable from "@/components/admin/base/item-management/AdminBaseItemTable.vue";

/**
 * AdminUserAddressTable component
 *
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        AdminBaseItemTable,
    },
})
export default class AdminUserAddressTable extends Vue {
    @Prop({ type: Boolean, default: false }) readonly editable!: boolean;
    @Prop({ type: Array, required: true })
    readonly userAddresses!: UserAddress[];

    private cities: City[] = [];
    private countries: Country[] = [];

    private defaultUserAddressItem: UserAddress = {
        id: -1,
        country: {
            id: -1,
            name: "",
        },
        city: {
            id: -1,
            plz: -1,
            name: "",
        },
        street: "",
        number: "",
        phoneNumber: "",
        fax: "",
    };

    /**
     * Function will be automatic called by Vue.js (see vue lifecycle)
     * Function fetches data, initialize variables etc.
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async created() {
        try {
            [this.countries, this.cities] = await Promise.all([
                this.fetchCountries(),
                this.fetchCities(),
            ]);
        } catch (err) {
            const errorMessage =
                err instanceof Error ? err.message : (err as string);

            AlertModule.showAlert({
                type: AlertType.ERROR,
                message: errorMessage,
            });
        }
    }

    /**
     * Fetches all countries
     *
     * @returns Promise<Country[]>
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async fetchCountries(): Promise<Country[]> {
        const response = (
            await axios.get<APIResponse<Country[]>>("/users/countries")
        ).data;
        if (response.status === "error") {
            throw new Error(response.message || "unknownError");
        }

        return response.data;
    }

    /**
     * Fetches all cities
     *
     * @returns Promise<City[]>
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async fetchCities(): Promise<City[]> {
        const response = (await axios.get<APIResponse<City[]>>("/users/cities"))
            .data;
        if (response.status === "error") {
            throw new Error(response.message || "unknownError");
        }

        return response.data;
    }

    /**
     * @returns table headers
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private get tableHeaders(): DataTableHeader[] {
        return [
            {
                text: this.$t("user.address.country").toString(),
                value: "country.name",
            },
            {
                text: this.$t("user.address.city").toString(),
                value: "city.name",
            },
            {
                text: this.$t("user.address.street").toString(),
                value: "street",
            },
            {
                text: this.$t("user.address.number").toString(),
                value: "number",
            },
        ];
    }
}
