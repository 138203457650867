


























































































































import { Component, Prop, Vue } from "vue-property-decorator";
import AlertModule, { AlertType } from "@/store/modules/alert";
import axios, { APIResponse } from "@/plugins/axios";
import { User, UserWithPassword } from "@/types/user";
import { Permission, PermissionGroup } from "@/types/user/permission";
import { DataTableHeader } from "vuetify/types/";
import { ArticlePriceClass } from "@/types/shop/article";
import AdminBaseItemTable from "@/components/admin/base/item-management/AdminBaseItemTable.vue";
import AdminUserAddressTable from "@/components/admin/user/address/AdminUserAddressTable.vue";
import PasswordTextField from "@/components/text-fields/password/PasswordTextField.vue";

/**
 * AdminUserTable component
 *
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        AdminBaseItemTable,
        AdminUserAddressTable,
        PasswordTextField,
    },
})
export default class AdminUserTable extends Vue {
    @Prop({ type: Boolean, default: false }) readonly editable!: boolean;

    private users: UserWithPassword[] = [];
    private permissionGroups: PermissionGroup[] = [];
    private permissions: Permission[] = [];
    private priceClasses: ArticlePriceClass[] = [];

    private defaultUserItem: UserWithPassword = {
        id: -1,
        firstname: "",
        lastname: "",
        email: "",
        image: {
            id: -1,
            src: "",
            alt: "",
        },
        addresses: [],
        permissions: [],
        customPermissions: [],
        groups: [],
        company: {
            id: -1,
            name: "",
            taxNumber: "",
            addresses: [],
            employees: [],
            responsibleId: -1,
            discount: 0,
        },
        priceClass: {
            id: -1,
            name: "",
            currency: "",
            currencyPosition: 2,
            tax: 0,
        },
        password: null,
        passwordWdh: null,
    };

    private changePassword = false;

    /**
     * @returns table headers
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private get tableHeaders(): DataTableHeader[] {
        return [
            {
                text: this.$t("user.firstname").toString(),
                value: "firstname",
            },
            {
                text: this.$t("user.lastname").toString(),
                value: "lastname",
            },
            {
                text: this.$t("user.email").toString(),
                value: "email",
            },
        ];
    }

    /**
     * Function will be automatic called by Vue.js (see vue lifecycle)
     * Function fetches data, initialize variables etc.
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async created() {
        let users: User[] = [];
        try {
            [
                users,
                this.permissionGroups,
                this.permissions,
                this.priceClasses,
            ] = await Promise.all([
                this.fetchUsers(),
                this.fetchPermissionGroups(),
                this.fetchPermissions(),
                this.fetchPriceClasses(),
            ]);

            this.users = users.map((user) => ({
                ...user,
                password: "",
                passwordWdh: "",
            }));
        } catch (err) {
            const errorMessage =
                err instanceof Error ? err.message : (err as string);

            AlertModule.showAlert({
                type: AlertType.ERROR,
                message: errorMessage,
            });
        }
    }

    /**
     * Fetches all users
     *
     * @returns Promise<User[]>
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async fetchUsers(): Promise<User[]> {
        const response = (await axios.get<APIResponse<User[]>>("/admin/user"))
            .data;
        if (response.status === "error") {
            throw new Error(response.message || "unknownError");
        }

        return response.data;
    }

    /**
     * Fetches all permission groups
     *
     * @returns Promise<PermissionGroup[]>
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async fetchPermissionGroups(): Promise<PermissionGroup[]> {
        const response = (
            await axios.get<APIResponse<PermissionGroup[]>>(
                "/admin/permissions/groups"
            )
        ).data;
        if (response.status === "error") {
            throw new Error(response.message || "unknownError");
        }

        return response.data;
    }

    /**
     * Fetches all permissions
     *
     * @returns Promise<Permission[]>
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async fetchPermissions(): Promise<Permission[]> {
        const response = (
            await axios.get<APIResponse<Permission[]>>("/admin/permissions")
        ).data;
        if (response.status === "error") {
            throw new Error(response.message || "unknownError");
        }

        return response.data;
    }

    /**
     * Fetches all price classes
     *
     * @returns Promise<ArticlePriceClass[]>
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async fetchPriceClasses(): Promise<ArticlePriceClass[]> {
        const response = (
            await axios.get<APIResponse<ArticlePriceClass[]>>(
                "/articles/priceclasses"
            )
        ).data;
        if (response.status === "error") {
            throw new Error(response.message || "unknownError");
        }

        return response.data;
    }
}
