var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AdminBaseItemTable',{attrs:{"title":_vm.$tc('user.address', 100),"items":_vm.userAddresses.map(function (item, key) { return (Object.assign({}, item, {key: key})); }),"item-key":"key","headers":_vm.tableHeaders,"editable":_vm.editable,"defaultCRUDItem":_vm.defaultUserAddressItem},on:{"crudDialogSave":function (updatedItems, crudDialogMode, crudItem, crudItemIndex) { return _vm.$emit(
                'crudDialogSave',
                updatedItems,
                crudDialogMode,
                crudItem,
                crudItemIndex
            ); }},scopedSlots:_vm._u([{key:"crud-form",fn:function(ref){
            var item = ref.item;
return [_c('v-select',{attrs:{"label":_vm.$t('user.address.country'),"items":_vm.countries,"item-text":"name","item-value":function (country) { return country; },"rules":[function (v) { return !!v; }],"required":""},model:{value:(item.country),callback:function ($$v) {_vm.$set(item, "country", $$v)},expression:"item.country"}}),_c('v-autocomplete',{attrs:{"label":_vm.$t('user.address.city'),"items":_vm.cities,"item-text":"name","item-value":function (city) { return city; },"rules":[function (v) { return !!v; }],"required":""},model:{value:(item.city),callback:function ($$v) {_vm.$set(item, "city", $$v)},expression:"item.city"}}),_c('v-text-field',{attrs:{"label":_vm.$t('user.address.street'),"rules":[function (v) { return !!v; }],"required":""},model:{value:(item.street),callback:function ($$v) {_vm.$set(item, "street", $$v)},expression:"item.street"}}),_c('v-text-field',{attrs:{"label":_vm.$t('user.address.number'),"rules":[function (v) { return !!v; }],"required":""},model:{value:(item.number),callback:function ($$v) {_vm.$set(item, "number", $$v)},expression:"item.number"}}),_c('v-text-field',{attrs:{"label":_vm.$t('user.address.phoneNumber'),"rules":[function (v) { return !!v; }],"required":""},model:{value:(item.phoneNumber),callback:function ($$v) {_vm.$set(item, "phoneNumber", $$v)},expression:"item.phoneNumber"}}),_c('v-text-field',{attrs:{"label":_vm.$t('user.address.fax')},model:{value:(item.fax),callback:function ($$v) {_vm.$set(item, "fax", $$v)},expression:"item.fax"}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }