














import { Component, Prop, Vue } from "vue-property-decorator";

/**
 * PasswordTextField component
 *
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component
export default class PasswordTextField extends Vue {
    @Prop({ type: String, required: true }) readonly value!: string;
    @Prop({ type: String, default: undefined }) readonly label!: string;
    @Prop({ type: Boolean, default: false }) readonly required!: boolean;
    @Prop({ type: Array, default: () => [] }) readonly rules!: ((
        value: string
    ) => boolean | string)[];

    private showPassword = false;
}
