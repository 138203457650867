






import { Component, Vue } from "vue-property-decorator";
import AdminUserTable from "@/components/admin/user/AdminUserTable.vue";

/**
 * AdminUserManagement view
 *
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        AdminUserTable,
    },
})
export default class AdminUserManagement extends Vue {}
